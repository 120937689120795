<script>
import { sitioBus } from "@/main";
import { mapGetters } from "vuex";
import { wpService } from "@/services/wp";
import _merge from "lodash/merge";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import insufficientFundsVue from "@/modals/insufficient-funds.vue";
const defaultQuery = {
  status: "",
  order: "DESC",
  paged: 1,
};
export default {
  name: "account-listings",

  metaInfo: {
    title: "Mis Anuncios",
  },

  statusOptions: {
    Todos: "",
    Vigentes: "active",
    Expirados: "expired",
  },

  components: {
    single: () =>
      import(
        /* webpackChunkName: "acc-listings-single" */ "@/views/acc-listings-single.vue"
      ),
    entry: () =>
      import(
        /* webpackChunkName: "acc-listings-entry" */ "@/components/acc-listings-entry.vue"
      ),
  },

  data() {
    return {
      watchFilters: true,
      fetching: false,
      fetched: false,
      entries: null,
      result: null,
      params: _merge({}, defaultQuery),
      paged: 1,
    };
  },

  computed: {
    ...mapGetters("session", ["userCredits"]),
    query() {
      return _merge({}, this.params, { paged: this.paged });
    },
    currentSingleEntry() {
      // return null;
      if (this.$route.name !== "acc-listings-single") return null;
      let ltg_id = {}.hasOwnProperty.call(this.$route.params, "ltg_id")
        ? Number(this.$route.params.ltg_id)
        : 0;
      if (!ltg_id) return null;
      return _find(this.entries, { ltg_id }) || null;
    },
  },

  mounted() {
    sitioBus.$on("refetch_account_listings", () => {
      console.log("RECEIVED refetch_account_listings");
      this.entries = null;
      this.fetchEntries();
    });
    if (!this.fetched) {
      this.fetchEntries();
    }
  },

  beforeDestroy() {
    sitioBus.$off("refetch_account_listings");
  },

  methods: {
    singleEntryUpdate(updatedEntry) {
      if (updatedEntry.ltg_id && this.entries && this.entries.length) {
        let entryIndex = _findIndex(this.entries, {
          ltg_id: updatedEntry.ltg_id,
        });
        if (entryIndex >= 0) {
          let entries = _merge([], this.entries);
          entries[entryIndex] = updatedEntry;
          this.entries = null;
          this.$nextTick(() => {
            this.entries = entries;
          });
        }
      }
    },
    deleteListing(ltg_id = 0) {
      console.log("delete-listing", ltg_id);
      let loading = this.$buefy.loading.open();
      wpService.listings
        .deleteSingle(ltg_id)
        .then(() => {
          if (this.$route.name !== "acc-listings") {
            this.$router.push({ name: "acc-listings" });
          }
          this.$buefy.toast.open({
            type: "is-success",
            message: "¡Anuncio eliminado!",
          });
          let entryIndex = _findIndex(this.entries, { ltg_id });
          if (entryIndex >= 0) {
            console.log("entry in entries", entryIndex);
            if (
              this.entries.length < 2 &&
              this.paged > 1 &&
              this.paged === this.result.pages
            ) {
              console.log("pagination move to ", this.paged - 1);
              this.paged--;
            } else {
              console.log("just refetch");
              this.fetchEntries();
            }
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    async fetchEntries() {
      this.fetching = true;
      this.entries = [];
      await wpService.account
        .accountListingsQuery({ params: this.query })
        .then(response => {
          // console.log(response);
          this.entries = response.entries;
          this.result = response;
          this.fetched = true;
        })
        .catch(err => {
          console.log(err);
        });
      this.fetching = false;
    },
    newListingConfirmation() {
      this.$buefy.dialog.confirm({
        title: "Confirmación",
        message: `<p>Nueva publicación con vigencia de 30 días.</p>
                  <p>Costo: <b>1 crédito</b></p>`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        onConfirm: () => {
          this.postSingle();
        },
      });
    },
    async postSingle() {
      if (!this.userCredits) {
        this.$buefy.modal.open({
          parent: this,
          component: insufficientFundsVue,
          hasModalCard: true,
        });
        return;
      }
      const loader = this.$buefy.loading.open();
      let ltg_id = await wpService.listings
        .postSingle()
        .then(response => {
          return response.ltg_id;
        })
        .catch(err => {
          console.log(err);
          return null;
        });
      if (ltg_id) {
        this.watchFilters = false;
        this.params = defaultQuery;
        this.paged = this.params.paged;
        this.$nextTick(function() {
          this.watchFilters = true;
        });
        // console.log("fetching from method: postSingle");
        await this.fetchEntries();
        // let single = _find(this.entries, { ltg_id });
        // if (single === undefined) {
        //   let trials = 1;
        //   while (trials < 3 && single === undefined) {
        //     setTimeout(() => {
        //       console.log(`Try ${trials}`);
        //       single = _find(this.entries, { ltg_id: ltg_id });
        //       trials++;
        //     }, 200);
        //   }
        // }
        this.openSingle(ltg_id);
      }
      loader.close();
    },
    openSingle(ltg_id) {
      if (
        this.$route.name == "acc-listings-single" &&
        this.$route.params.ltg_id == ltg_id
      ) {
        return;
      }
      this.$router.push({
        name: "acc-listings-single",
        params: {
          ltg_id: ltg_id,
        },
      });
    },
    toggleOrder() {
      this.params.order = this.params.order === "DESC" ? "ASC" : "DESC";
    },
  },

  watch: {
    params: {
      deep: true,
      handler: function() {
        if (this.paged !== this.params.paged) {
          this.paged = this.params.paged;
          return;
        }
        if (!this.watchFilters) return;
        // console.log("fetching from watch: params");
        this.fetchEntries();
      },
    },
    paged() {
      if (!this.watchFilters) return;
      // console.log("fetching from watch: paged");
      this.fetchEntries();
    },
  },
};
</script>

<template>
  <sitio-layout id="account_listings">
    <div class="app__view">
      <div class="listings__feed">
        <div class="content_width page_gap">
          <div class="page__header">
            <!-- <h1 class="page__title">Mis Anuncios</h1> -->
            <h1 class="page__title">Publica</h1>
            <button
              v-if="$mq === 'sm'"
              class="button btnCreateListing btnCreateListing--mobile is-primary is-rounded"
              @click="newListingConfirmation"
            >
              <b-icon icon="plus" />
            </button>
            <b-button
              v-else
              class="btnCreateListing btnCreateListing--desktop"
              type="is-primary"
              icon-left="plus"
              @click="newListingConfirmation"
            >
              <span class="text">Crear anuncio</span>
            </b-button>
            <!-- <h1 class="page__title">Panel de publicación</h1> -->
          </div>
          <div class="credits">
            <span class="c_label">Créditos disponibles:</span>
            <span class="c_value">${{ userCredits | formatMoney(0) }}</span>
          </div>
          <div class="filters_bar">
            <div class="status__tabs">
              <button
                v-for="(value, key) in $options.statusOptions"
                :key="key"
                @click="params.status = value"
                class="tab"
                :class="{ current: params.status === value }"
              >
                {{ key }}
              </button>
            </div>
            <button class="filter-direction" @click="toggleOrder()">
              <i
                v-if="params.order === 'DESC'"
                class="fas fa-sort-numeric-down-alt"
              ></i>
              <i v-else class="fas fa-sort-numeric-down"></i>
            </button>
          </div>
          <b-loading
            v-if="!fetched || fetching"
            :active="true"
            :is-full-page="false"
          />
          <div v-else-if="entries" class="page__body">
            <template v-if="entries.length">
              <div class="entries">
                <entry
                  v-for="listing in entries"
                  :key="listing.ltg_id"
                  :entry="listing"
                  class="entry"
                  @click.native="openSingle(listing.ltg_id)"
                />
              </div>
              <b-pagination
                v-if="result.pages > 1"
                class="is-primary"
                :total="result.found"
                :per-page="result.filters.show"
                :current.sync="paged"
                :range-before="1"
                :range-after="1"
                :simple="true"
                :rounded="false"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
              >
                <b-pagination-button
                  slot-scope="props"
                  :page="props.page"
                  :id="`page${props.page.number}`"
                  tag="button"
                >
                  {{ props.page.number }}
                </b-pagination-button>

                <b-pagination-button
                  slot="previous"
                  slot-scope="props"
                  :page="props.page"
                  tag="button"
                >
                  <b-icon icon="angle-left" />
                </b-pagination-button>

                <b-pagination-button
                  slot="next"
                  slot-scope="props"
                  :page="props.page"
                  tag="button"
                >
                  <b-icon icon="angle-right" />
                </b-pagination-button>
              </b-pagination>
            </template>
            <div v-else class="nolistings">
              <div class="content">
                <p>Actualmente no tienes ningun anuncio.</p>
                <p v-if="!userCredits">
                  No es posible crear anuncios si no tienes créditos.
                  <router-link to="/account/plan"
                    >Regarca tu cartera</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <single
        v-if="$route.name === 'acc-listings-single'"
        :prefill="currentSingleEntry"
        @entry-updated="fetchEntries"
        @entry-delete="deleteListing"
      />
    </div>
  </sitio-layout>
</template>

<style lang="scss" scoped>
.btnCreateListing--mobile {
  position: fixed;
  width: 4rem;
  height: 4rem;
  padding: 0;
  top: 1rem;
  margin-bottom: calc(62px + var(--safe-area-inset-bottom));
  z-index: 1;
  right: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.18);
}
.btnCreateListing--desktop {
  font-weight: bold;
}

.page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sitioApp:not(.layout--sm) {
  .credits {
    display: inline-block;
    > :nth-child(2) {
      margin-left: 0.25rem;
    }
  }
}

.app__view {
  position: relative;
}
.content_width {
  position: relative;
}
.credits {
  background-color: $sitio2;
  background-color: #3a3a3a;
  background-color: #efefef;
  // color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 14px;
  text-align: center;
  margin-top: 0.25rem;
  margin-top: 0.75rem;
  .c_value {
    font-weight: bold;
  }
}
.filters_bar {
  margin-top: 1rem;
  margin-top: 0.375rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  button:not(.button) {
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    // -ms-touch-action: manipulation;
    touch-action: manipulation;
    background: transparent;
    display: inline-block;
    // &:focus {
    //   outline: none !important;
    // }
  }
  .status__tabs {
    // margin-bottom: 1rem;
    display: flex;
    .tab {
      border-style: solid;
      border-color: black;
      width: auto;
      font-family: inherit;
      font-size: 14px;
      line-height: 18px;
      position: relative;
      padding: 16px;
      font-weight: 600;
      border: none;
      color: #717171;

      // border: none;
      // background-color: none;
      // font-weight: 600;
      // padding: 1rem;
      // font-size: 0.9375rem;
      // border: 1px solid #ddd;
      &:first-child {
        margin-left: -1rem;
      }
      &::after {
        content: "";
        opacity: 0;
        display: block;
        padding-top: 1rem;
        margin-bottom: -1rem;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: #222222;
        -webkit-border-image: #222222 2px;
        border-image: #222222 2px;
      }
      &.current {
        color: #000;
        &::after {
          opacity: 1;
        }
      }
      @media (min-width: 375px) {
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
    }
  }
  .filter-direction {
    // padding: 12px;
    width: 38px;
    height: 38px;
    text-align: center;
    border: 1px solid #ccc;
    // border: 0;
    border-radius: 4px;
    font-size: 18px;
    padding: 0;
  }
}
#account_listings /deep/ {
  .entries {
    // padding-left: calc(2 * var(--sitio-ui-gap-size));
    padding-left: var(--sitio-ui-gap-size);
  }
  .entry {
    user-select: none;
    cursor: pointer;
    display: flex;
    max-width: 100%;
    width: 100%;
    font-size: 0.875rem;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.75em;
    padding-left: 0;
    margin-bottom: 1.5em;
    background-color: rgba(255, 255, 255, 0.35);
    box-shadow: #00000007 1px 1px, #00000009 4px 4px 10px, #fff -1px -1px,
      #fff -4px -4px 10px;
    .entry-picture {
      flex: 0 0 6.5em;
      margin-top: -1rem;
      // margin-left: -1.25rem;
      margin-left: calc(0rem - var(--sitio-ui-gap-size));
    }
    .content-area {
      width: calc(100% - 5.5em);
      // flex: 0 0 auto;
      padding-top: 0.5em;
      padding-left: 1em;
    }
    .featured-cover {
      position: relative;
      .size-holder {
        display: block;
        width: 100%;
      }
      .cover {
        background-color: #e8e8e8;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1em;
        overflow: hidden;
        // box-shadow: #ecf0f4 0px 1px 4px 1px;
        box-shadow: 0px 12px 13px -10px rgba(0, 0, 0, 0.42);
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
    .entry-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 0.875em;
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
      // border-bottom: 1px solid #f2f2f2;
      // justify-content: space-between;
      > :last-child {
        padding-right: 0 !important;
      }
      > * {
        font-size: 0.875em;
      }
      .entry-title {
        font-size: 1.125em;
        color: #000;
        font-weight: bold;
        flex: 0 0 30%;
        .da__label {
          display: none;
        }
      }
      .entry-expiry {
        flex: 1 0 auto;
        justify-content: flex-end;
        text-align: right;
        padding: 0 0.25em;
        &:not(.good) {
          color: $danger;
          font-weight: bold;
        }
        &.good::before {
          display: inline;
          content: "Exp. ";
        }
        .da__value {
          color: #222;
          font-weight: 500;
          position: relative;
          &::after {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            border-bottom: 1px solid #ccc;
            border-color: rgba(35, 209, 96, 0.45);
            left: 0;
          }
        }
      }
      .optional {
        color: #222;
        flex: 0 0 2.5em;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        font-weight: 500;
        > :not(:last-child) {
          margin-right: 0.5em;
        }
        .da__label {
          display: none;
        }
        .da__value {
          color: #000;
          position: relative;
          &::after {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            border-bottom: 1px solid #ccc;
            left: 0;
          }
        }
        .da__icon {
          width: 1.5em;
          height: 1.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 0.875em;
        }
        &.good {
          .da__icon {
            background-color: $success;
            background-color: rgba($success, 0.45);
          }
          .da__value::after {
            border-color: rgba($success, 0.45);
          }
        }
        &:not(.good) {
          opacity: 0.45;
          .da__icon {
            background-color: rgba(#b1b1b1, 0.45);
          }
          .da__value::after {
            border-color: rgba($danger, 0.45);
          }
        }
      }
    }

    .entry-category,
    .entry-location {
      font-size: 0.8125em;
      color: #444;
    }

    .entry-category {
      font-weight: 600;
    }

    .entry-location {
      font-weight: 500;
      opacity: 0.8;
    }

    .entry-location {
      &,
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
      }
    }

    .box__details {
      display: flex;
      > :not(:first-child) {
        margin-left: 0.5em;
        border-left: 1px solid #ccc;
        padding-left: 0.5em;
      }
      .da__label,
      .da__value {
        font-weight: normal;
      }
      .da__value {
        opacity: 1;
      }
      .da__label:before {
        display: inline;
        content: "\00a0";
      }
    }
  }
  .pagination {
    margin: 0;
  }

  @media (max-width: 52.4375em) {
    > .loading-overlay {
      position: fixed;
      z-index: 10;
    }
  }

  @media (min-width: 32em) {
    .entry {
      .entry-header {
        .entry-title {
          flex: 0 0 23%;
          .da__label {
            display: inline;
          }
        }
        .entry-expiry {
          flex: 0 0 33%;
          padding: 0 0.5em;
          text-align: center;
        }
        .optional {
          padding: 0 0.5em;
          flex: 0 0 22%;
          justify-content: center;
          .da__label {
            display: inline;
          }
        }
      }
    }
  }

  @media (min-width: 40em) {
    .listings__feed {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
    .entry {
      .entry-header {
        .entry-title {
          flex: 0 0 20%;
        }
        .entry-expiry {
          flex: 0 0 30%;
        }
        .optional {
          flex: 0 0 25%;
        }
      }
    }
  }

  // @media (min-width: 52.5em) {
  //   .app__view {
  //     display: flex;
  //     flex-direction: row;
  //   }
  // }

  @media (min-width: 64em) {
    .entry {
      font-size: 1rem;
    }
  }
}
.nolistings {
  text-align: center;
}
</style>
